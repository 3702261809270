.card{


  .card-header{
    background-color: $transparent-bg;
    border-bottom: 0;
    background-color: transparent;
    border-radius: 0;
    padding: 0;
  }

  .card-footer{
    padding: 0;
    margin-top: 15px;
    background-color: $transparent-bg;
    border:0;
  }

  &[data-background-color="orange"]{
      background-color: $primary-color;
  }

  &[data-background-color="red"]{
      background-color: $danger-color;
  }

  &[data-background-color="yellow"]{
      background-color: $warning-color;
  }

  &[data-background-color="blue"]{
      background-color: $info-color;
  }

  &[data-background-color="green"]{
      background-color: darken($success-color, 5%);
  }
}
