.slick-prev{
  left: -48px !important;
  
}
.slick-next{

  
  
}
.slick-next:before, .slick-prev:before {
  color: #163365 !important;
  
}
.slick-dots li button:before {
  color: #163365 !important;
}
.slick-slide img {
  display: block;
  padding:20px;
}
.centered-element {
  margin: 0;
  position: absolute;
  max-width: 200px;
  top: 50%;
  transform: translateY(-50%);
}

  .hr {
    display: inline-block;
  }
  .hr:after {
    content: '';
    display: block;
   
    border-top: 4px solid #bd9b4d;
    margin-top: 6px;
  }
  .title{
    text-align: left !important;
    margin-left: 0px;
  }
 #grammi {
    position: relative;
    top: 0px;
    border: none;
    padding:0px;
    border-top: 1px solid #bd9b4d;}